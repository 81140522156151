import Vue from "vue"
const NAMESPACE = "networks"

const actions = {
  /**
   * Get delivery request status(Assigment)
   * @param app
   * @param {{organization_uid: string, unique_id: string}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getDeliveryRequest(app, data) {
    return await Vue.axios.get(
      `networks/delivery_request/view/${data.organization_uid}/${data.unique_id}`
    )
  },
  async cancel(app, data) {
    return await Vue.axios.post(
      `/${NAMESPACE}/delivery_request/${data.organization_uid}/${data.unique_id}/cancel`
    )
  },
  async confirmRpki(app, data) {
    const payload = {
      uuid: data.uuid,
      action: data.action,
    }
    return await Vue.axios.post(
      `/${NAMESPACE}/delivery_request/${data.organization_uid}/confirm_rpki`,
      payload
    )
  },
  /**
   * Create Reverse dns request
   * @param app
   * @param {{organization_uid: string, cidr: string, nservers: Object}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async sendRDNSRequest(app, data) {
    return await Vue.axios.post(`/${NAMESPACE}/rdns_request/${data.organization_uid}`, data)
  },
  /**
   * Get RDNS status for both: customer supplier
   * @param app
   * @param {{organization_uid: string, cidr: string}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getRDNSStatus(app, data) {
    return await Vue.axios.get(`/${NAMESPACE}/rdns_request/${data.organization_uid}/search`, {
      params: {
        cidr: data.cidr,
      },
    })
  },
  /**
   * Decline/Approve action
   * @param app
   * @param {{organization_uid: string, rdns_request_uid: string, action: string}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async supplierRDNSRequest(app, data) {
    return await Vue.axios.post(
      `/${NAMESPACE}/rdns_request/${data.organization_uid}/${data.rdns_request_uid}/approve_decline`,
      data
    )
  },
  /**
   * Detail RDNS
   * @param app
   * @param {{organization_uid: string, rdns_request_uid: string, action: string}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async RDNSDetail(app, data) {
    return await Vue.axios.get(
      `/${NAMESPACE}/rdns_request/details/${data.organization_uid}/${data.rdns_request_uid}`,
      data
    )
  },
  /**
   * Cancel RDNS request
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   * @constructor
   */
  async RDNSCancel(app, data) {
    return await Vue.axios.post(
      `/${NAMESPACE}/rdns_request/${data.organization_uid}/${data.rdns_request_uid}/cancel`
    )
  },
  /**
   * Load availability of the networks
   * @param app
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  async loadAvailability(app, params) {
    let url = `/${NAMESPACE}/delivery_request/availability/${params.organization_uid}`
    let commit = true
    if (params.order_uid) {
      url += `?order_uid=${params.order_uid}`
      commit = false
    }
    const response = await Vue.axios.get(url)
    if (commit) {
      app.commit("setAvailabilities", {
        organization_uid: params.organization_uid,
        options: response.data,
      })
    }
    return response
  },
  async getWithdrawalOptions(app, params) {
    return await Vue.axios.get(
      `/marketplace/withdrawal/${params.org_uid}/${params.inetnum_uid}/options`
    )
  },
  async withdrawalRequest(app, params) {
    return await Vue.axios.post(
      `/marketplace/withdrawal/${params.org_uid}/${params.inetnum_uid}`,
      params
    )
  },
  async returnRequest(app, params) {
    return await Vue.axios.post(
      `/marketplace/withdrawal/return/${params.org_uid}/${params.inetnum_uid}`,
      params
    )
  },
}
const state = {
  availabilities: {},
}
const getters = {
  getAvailabilities(state) {
    return state.availabilities
  },
}
const mutations = {
  setAvailabilities(state, params) {
    state.availabilities[params.organization_uid] = params.options
  },
}
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
